<template>
  <div class="add-item">
    <div v-loading="is_pending" class="add-item-form">
        <div class="add-item-form-header">
            <h2 v-if="!edit" >הוספת פריט חדש</h2>
            <h2 v-else >עריכת פריט קיים</h2>
        </div>
        <div class="add-item-form-content">
            <div class="add-item-form-content-filed">
                <h2>מזהה פריט</h2>
                <el-input type="number" v-model="item['פריט']" placeholder="מספר פריט" />
            </div>
            <div class="add-item-form-content-filed">
                <h2>שם פריט בעברית</h2>
                <el-input type="text" v-model="item['שם פריט']" placeholder="שם פריט עברית" />
            </div>
            <div class="add-item-form-content-filed">
                <h2>שם פריט בלועזית</h2>
                <el-input type="text" v-model="item['שם פריט לועזית']" placeholder="שם פריט לועזית" />
            </div>
        </div>
        <div class="add-item-form-footer">
            <el-button v-if="!edit" @click="handle_submit" style="width:80%;" type="success">הוספה</el-button>
            <el-button v-else @click="handle_submit_edit" style="width:80%;" type="warning">עדכון</el-button>
        </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import {slide_pop_error,alert} from '../../../../../Methods/Msgs'
import {save_single_item_in_db,check_if_item_exist_in_db,save_edited_single_item_in_db} from '../scripts/scripts'
import { onActivated, onDeactivated } from '@vue/runtime-core'
export default {
    emits:['reload_data','reset_edit','after_edit'],
    props:['edit'],
    setup(props,{emit}){

        const is_pending = ref(false)

        const err_msg = ref('')

        const item = ref({
            'פריט':'',
            'שם פריט':'',
            'שם פריט לועזית':''
        })

        const validation = () => {
           for (const [key, value] of Object.entries(item.value)) {
            switch (key) {
                case 'פריט':
                    if(!value){
                        err_msg.value = 'עליך להזין מזהה פריט!'
                        return false
                    }
                    break;
                case 'שם פריט':
                    if(!value){
                        err_msg.value = 'עליך להזין שם פריט בעברית!'
                        return false
                    }
                    break;
                case 'שם פריט לועזית':
                    if(!value){
                        err_msg.value = 'עליך להזין שם פריט בלועזית!'
                        return false
                    }
                    break;
            }
           }
           return true
        }

        const validation_if_exist = async() => {
            const res = await check_if_item_exist_in_db(item.value)
            if(!res){
                err_msg.value = `הפריט ${item.value['פריט']} כבר קיים במערכת!`
                return false
            }
            return true
        }

        const handle_submit = async() => {
            if(!validation()){
                slide_pop_error(err_msg.value)
            }
            else if(!await validation_if_exist()){
                slide_pop_error(err_msg.value)
            }
            else{
                is_pending.value = true
                await save_single_item_in_db(item.value)
                is_pending.value = false
                alert('success','הצלחה',
                `הפריט ${item.value['פריט']} הוסף בהצלחה`)
                .then(()=>{
                    reset()
                    emit('reload_data')
                })
            }
        }

        const handle_submit_edit = async() => {
            if(!validation()){
                slide_pop_error(err_msg.value)
            }
            else{
                is_pending.value = true
                await save_edited_single_item_in_db(item.value)
                is_pending.value = false
                alert('success','הצלחה',
                `הפריט ${item.value['פריט']} עודכן בהצלחה`)
                .then(()=>{
                    reset()
                    emit('after_edit')
                })
            }
        }

        const reset = () => {
             item.value = {
                'פריט':'',
                'שם פריט':'',
                'שם פריט לועזית':''
            }
        }

        const init = () => {
            if(props.edit){
                item.value = props.edit
            }
        }

        init()

        onActivated(() => {
         if(props.edit){
            item.value = props.edit
         }else{
            reset()
         }
        })

        onDeactivated(()=>{
            if(props.edit){
                emit('reset_edit')
            }
        })


        return{
            handle_submit,
            handle_submit_edit,
            item,
            err_msg,
            is_pending,
        }
    }
}
</script>

<style scoped>
    .add-item{
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .add-item-form{
        width: 100%;
        max-width: 500px;
        height: fit-content;
        margin: 5px auto;
    }
    .add-item-form-content{
        width: 100%;
        height: fit-content;
    }
    .add-item-form-content-filed{
        width: 100%;
        height: fit-content;
        margin-bottom: 5px;
        padding: 0 5px;
    }
    .add-item-form-header,.add-item-form-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        background: var(--secondary);
        border-radius: 10px;
    }

   
</style>